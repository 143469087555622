<template>
  <div>
    <b-form-group label="Informar materiais" label-for="tags-with-dropdown" ref="materialDropdown">
      <b-form-tags id="tags-with-dropdown" v-model="formMateriais" no-outer-focus :no-tag-remove="true" class="mb-1">
        <template v-slot="{ tags, disabled, addTag }">
          <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-1">
            <li v-for="tag in tags" :key="tag" class="list-inline-item">
              <b-form-tag :title="tag" size="sm" no-remove variant="info">
                {{ tag }}
              </b-form-tag>
            </li>
          </ul>

          <b-dropdown id="dropdown-aria" size="sm" variant="outline-secondary" no-flip block menu-class="w-100" style="z-index: 999;">
            <template #button-content>
              <b-icon icon="tag-fill"></b-icon> Selecione os Materiais
            </template>
            <b-dropdown-form>
              <b-row>
                <b-col sm="12" md="7" lg="7" class="mt-1">
                  <!-- button on both side -->
                  <b-input-group size="sm">
                    <b-form-input v-model="search" placeholder="Procurar" />
                    <b-input-group-append>
                      <b-button variant="outline-primary" @click="search = ''">
                        Limpar
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <!--                <b-col sm="12" md="5" lg="5" class="mt-1">
                                  <b-button
                                      variant="outline-success"
                                      size="sm"
                                      @click="mostraInputMateriais = !mostraInputMateriais"
                                  >
                                    <feather-icon icon="PlusIcon"></feather-icon> Material na Lista
                                  </b-button>
                                </b-col>-->
              </b-row>
              <!--
                            <ModalMaterialRecDentistas
                                v-if="mostraInputMateriais"
                                :componentes="options"
                                @materialCadastrado="options.push($event)"
                                @mostraInputMateriais="mostraInputMateriais = $event"
                            />-->
            </b-dropdown-form>
            <b-dropdown-divider></b-dropdown-divider>
            <template v-for="(material, index) in materiaisComputados">
              <b-row class="pl-2 pr-2" v-if="material.mostrar">
                <b-col cols="8">
                  <b-form-checkbox
                      class="mb-1"
                      v-model="material.checked"
                      @change="addMaterial"
                  >
                    {{ material.nome }}
                  </b-form-checkbox>
                </b-col>
                <b-col cols="4" class="float-right">
                  <b-form-spinbutton
                      id="quantidade"
                      v-if="material.checked"
                      size="sm"
                      v-model="material.qtd"
                      @change="addMaterial"
                      placeholder="Qtd"
                  />
                </b-col>
              </b-row>
            </template>

            <b-dropdown-text v-if="options.length === 0">
              Nenhum material Cadastrado
            </b-dropdown-text>
          </b-dropdown>
        </template>
      </b-form-tags>
    </b-form-group>
  </div>

</template>

<script>
import {
  BIcon,
  BFormGroup,
  BFormTag,
  BFormTags,
  BDropdownItem,
  BInputGroupPrepend,
  BInputGroupAppend,
  BForm,
  BAlert,
  BDropdown,
  BDropdownForm,
  BFormInput,
  BDropdownDivider,
  BDropdownItemButton,
  BFormCheckbox,
  BInputGroup,
  BRow,
  BFormSpinbutton,
  BCol,
  BButton,
  BDropdownText
} from 'bootstrap-vue'
import {ValidationProvider} from "vee-validate";

export default {
  props: {
    componentesEditar: {
      required: false
    }
  },
  components: {
    BIcon,
    BButton,
    BCol,
    BFormSpinbutton,
    BRow,
    BInputGroup,
    ValidationProvider,
    BFormCheckbox,
    BFormGroup,
    BFormTag,
    BFormTags,
    BDropdownItem,
    BInputGroupPrepend,
    BInputGroupAppend,
    BForm,
    BAlert,
    BDropdown,
    BDropdownForm,
    BFormInput,
    BDropdownDivider,
    BDropdownItemButton,
    BDropdownText,
  },
  data() {
    return {
      options: [],
      search: '',
      liDisplay: 'block',
      value: [],
      formMateriais: [],
      formMateriaisEmit: [],
      mostraInputMateriais: false
    }
  },
  mounted() {
    this.listaMateriaisRecebidos();
  },

  computed: {
    materiaisComputados() {

      let result = []

      if(this.options.length) {

        let search = this.search.toLowerCase()

        this.options.forEach( (v) => {

          let mostrar = true
          let nome = v.nome.toLowerCase()


          if(this.search.length) {
            mostrar = nome.includes(search);

          }

          v.mostrar = mostrar
          result.push(v)

        })
      }

      return result

    }
  },

  methods: {

    limpaMateriaisSubmit() {

      this.formMateriais = []
      this.formMateriaisEmit = []
      this.options.forEach( v => v.checked = false)

    },

    listaMateriaisRecebidos() {
      this.$http("odontodocs/material/1").then((res) => {
        this.setaQuantidadeMateriais(JSON.parse(res.data.nome))
      });
    },

    async setaQuantidadeMateriais(materiais) {
      if(!materiais) return

      await materiais.forEach( (v) => {
        this.options.push({ nome: v.nome, qtd: 1})
      })

      this.options.sort(this.ordenaMateriais)

      if(this.componentesEditar.length) {
        this.setaMaterialEditar()
      }

    },
    setaMaterialEditar() {

      this.componentesEditar.forEach( (v) => {

        let index = this.options.findIndex( val => val.nome.trim() === v.nome.trim())

        if(index > -1) {

          this.options[index].checked = true
          this.options[index].qtd = v.qtd ?? ''

        }else{

          this.options.push({
            nome: v.nome,
            qtd: v.qtd ?? '',
            checked: true
          })
        }

      })

      this.addMaterial()
    },
    ordenaMateriais(a, b) {
      if (a.nome < b.nome) {
        return -1;
      }
      if (a.nome > b.nome) {
        return 1;
      }
    },
    addMaterial() {

      this.formMateriais = []
      this.formMateriaisEmit = []

      this.options.forEach( (v) => {
        if(v.checked){
          this.formMateriaisEmit.push({ nome: v.nome, qtd: v.qtd })
          this.formMateriais.push(v.qtd+' '+v.nome)
        }
      })

      this.$emit('materiais', this.formMateriaisEmit)

    }

  }
}
</script>

<style scoped>
::v-deep .dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
}
</style>

